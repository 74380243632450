import {applyQueryParam, isString} from "@juulsgaard/ts-tools";

export type CacheBuster = Date|string|number|undefined;

export function formatCacheBuster(cb: CacheBuster) {
  if (!cb) return undefined;

  if (isString(cb)) {
    const date = Date.parse(cb);
    if (isNaN(date)) return cb;
    return (date / 1000).toFixed(0)
  }

  if (cb instanceof Date) {
    return (cb.getTime() / 1000).toFixed(0);
  }

  return cb.toFixed(0);
}

export function applyCacheBuster(url: string, cb: CacheBuster) {
  const cbStr = formatCacheBuster(cb);
  if (!cbStr) return url;
  return applyQueryParam(url, '_cb', cbStr);
}
