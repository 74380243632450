import {environment} from "@environments/environment";
import {applyCacheBuster, CacheBuster} from "@lib/tools/url";

export module Media {

  export function UserProfile(userId: string, size: 'large'|'small'|'original', cb?: CacheBuster) {
    const url = `${environment.storageUrl}users/${userId}/profile/${size}`;
    return applyCacheBuster(url, cb);
  }

  export function AgendaMap(agendaId: string, mapId: string, size: 'small' | 'large', cb?: CacheBuster) {
    const url = `${environment.storageUrl}embedded/agendas/${agendaId}/maps/${mapId}/${size}`;
    return applyCacheBuster(url, cb);
  }

  export function RenderingBackground(renderingId: string, size: 'small' | 'large' | 'original', cb?: CacheBuster) {
    const url = `${environment.storageUrl}embedded/renderings/${renderingId}/background/${size}`;
    return applyCacheBuster(url, cb);
  }

  export function ArAsset(configId: string, assetId: string, type: 'original' | 'large' | 'icon', cb?: CacheBuster) {
    const url = `${environment.storageUrl}ar-apps/${configId}/assets/${assetId}/${type}`;
    return applyCacheBuster(url, cb);
  }

}
